<template>
  <div>
    <div class="box-filter">
      <StatusUpdateDados />
      <FilterDatepicker @getDataSelect="setDataFilter" />
    </div>

    <div class="primary">
      <div class="box table">
        <HeaderGraphic :data="chartData" />
        <div class="painel categoria">
          <Pie :chartData="chartData.items" />
        </div>
      </div>
      <div class="box table">
        <div class="box__header">
          <HeaderGraphic :data="tableData" />
          <a class="btn-download" @click="downloadCSV()" download>Download CSV</a>
        </div>
        <div class="painel">
          <b-table :items="tableData.items"></b-table>
          <paginate
            v-model="currentPage"
            :page-count="lastPage"
            :prev-text="'‹'"
            :next-text="'›'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterDatepicker from "@/components/filter-datepicker/FilterDatepicker.vue";
import HeaderGraphic from "@/components/header-graphic/HeaderGraphic.vue";
import StatusUpdateDados from "@/components/status-update-dados/StatusUpdateDados.vue";
import Pie from "@/components/charts/Pie.vue";
import { numberToReal } from "@/utils/money";
import { chartColors } from "@/utils/chart";
import { listarCategoriasMaisVendidas, getCSV } from "@/api/categorias";

export default {
  components: {
    HeaderGraphic,
    FilterDatepicker,
    StatusUpdateDados,
    Pie,
  },
  data() {
    return {
      tableData: {},
      chartData: {},
      perPage: 15,
      currentPage: 1,
      start: null,
      end: null,
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapState({
      accountActive: state => state.accounts.accountActive,
      getCategorias: state => state.categorias.categorias,
      lastPage: state => state.categorias.categorias_last_page,
    }),
  },
  methods: {
    listarCategoriasMaisVendidas,
    getCSV,
    downloadCSV() {
      this.getCSV(this.start, this.end);
    },
    loadData() {
      this.listarCategoriasMaisVendidas(this.tart, this.end, this.perPage, this.currentPage);
    },
    setDataFilter({ start, end }) {
      (this.start = start), (this.end = end);
      this.listarCategoriasMaisVendidas(start, end, this.perPage, this.currentPage);
    },

    loadTable(value) {
      const items = [];

      if (value.data) {
        value.data.map(line => {
          items.push({
            id: line.categoriesIds,
            nome: line.categoriesNames,
            pedidos: line.qtd_pedidos,
            "Produtos vendidos": line.qtd_itens_vendidos,
            receita: numberToReal(line.valor_vendido),
            "Ticket Médio": numberToReal(line.ticket_medio),
          });
        });
      }

      let data = {
        title: {
          top: "Tabela de",
          name: "Departamentos e categorias",
        },
        items,
      };

      return (this.tableData = data);
    },

    loadChart(value) {
      const labels = [];
      const datasets = [];

      if (value.data) {
        value.data.map(line => {
          labels.push( `(${line.categoriesIds}) ${line.categoriesNames} - R$` );
          datasets.push( line.valor_vendido );
        });
      }

      const data = {
        title: {
          top: "Gráfico de",
          name: "Departamentos e categorias",
        },
        items: {
          labels: labels,
          datasets: [
            {
              backgroundColor: chartColors,
              data: datasets
            }
          ],
        },
      };
      return (this.chartData = data);
    }
  },
  watch: {
    getCategorias(data) {
      this.loadChart(data);
      this.loadTable(data);
    },
    currentPage(newPage) {
      this.currentPage = newPage;
      this.listarCategoriasMaisVendidas(this.start, this.end, this.perPage, this.currentPage);
    },
    accountActive(data) {
      if (data) this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.box-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.categorias {
  height: 100%;
  .page-container {
    margin: 30px 0;
  }
}

.small {
  max-width: 600px;
  margin: 150px auto;
}

.primary {
  display: flex;
}

.box {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  max-width: calc(50% - 7.5px);
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 18px;
  box-shadow: 0 3px 6px #c3d0e029;
  border-radius: 20px;
  height: 400px;
  &:nth-child(2) {
    margin-right: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  .painel {
    position: relative;
    height: 300px;
    display: block;
    overflow: hidden;
    &.categorias {
      table {
        background-color: red;
        tbody {
          tr {
            &:first-child {
              td {
                &:after {
                  content: "";
                  background-image: url("~@/assets/images/icones/icone-selo-first-position.svg");
                  width: 34px;
                  height: 46px;
                  right: -10px;
                  bottom: -15px;
                  position: absolute;
                  background-position: center;
                }
              }
            }
          }
        }
      }
    }
  }
  &.table {
    max-width: calc(70%);
    height: auto;
    &:after {
      content: "";
      display: block;
    }

    &:first-child {
      max-width: calc(30% - 15px);
      display: inline-table;
    }

    .painel {
      height: auto;
      overflow: inherit;

      table {
        td[aria-colindex="5"] {
          white-space: nowrap;
          text-align: right;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .primary {
    flex-direction: column;
  }
  .box-filter {
    justify-content: flex-end;
  }
  .box {
    &.table {
      max-width: 100%;
      margin-top: 30px;
      margin-left: 0;
      padding: 25px;
      overflow-x: scroll;
      &:first-child {
        max-width: 100%;
        margin-top: 0;
      }
    }
  }
}
</style>
