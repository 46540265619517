export const chartColors = [
    // "#5376F6",
    // "#B30E37",
    // "#DDD33E",
    // "#FF2E63",
    // "#14C3FF",
    // "#178BB3",
    // "#B3948B",
    // "#A3D36E",
    // "#FF8765",
    // "#4D74FF",
    // "#3E58B3",
    // "#EB453B",
    // "#88C863",

    // "#ffb6c1", // LightPink
    // "#ffdead", // NavajoWhite
    // "#98fb98", // PaleGreen
    // "#add8e6", // LightBlue
    // "#d8bfd8", // Thistle
    // "#f0e68c", // Khaki
    // "#ffec8b", // LightGoldenRodYellow
    // "#ffcccb", // PeachPuff
    // "#b0e0e6", // PowderBlue
    // "#f5deb3", // Wheat
    // "#c1ffc1", // PaleGreen
    // "#b0c4de", // LightSteelBlue
    // "#ffdab9", // PeachPuff
    // "#c1ffc1", // PaleGreen
    // "#e6e6fa", // Lavender
    // "#afeeee", // PaleTurquoise
    // "#ffdab9", // PeachPuff
    // "#dda0dd", // Plum
    // "#ffebcd", // BlanchedAlmond
    // "#90ee90", // LightGreen
    // "#dda0dd", // Plum
    // "#ffb6c1", // LightPink
    // "#98fb98", // PaleGreen
    // "#b0c4de", // LightSteelBlue
    // "#87cefa", // LightSkyBlue
    // "#d8bfd8", // Thistle
    // "#ffc0cb", // Pink
    // "#00ced1", // DarkTurquoise
    // "#87cefa", // LightSkyBlue
    // "#dda0dd", // Plum
    // "#98fb98", // PaleGreen
    // "#7cfc00", // LawnGreen
    // "#b0e0e6", // PowderBlue
    // "#ffb6c1", // LightPink
    // "#palegreen", // LightPink
    // "#dda0dd", // Plum
    // "#98fb98", // PaleGreen
    // "#ff4500", // OrangeRed
    // "#dda0dd", // Plum
    // "#ffb6c1"  // LightPink

    "#fb5607",
    "#8338ec",
    "#3a86ff",
    "#ff7f00", // DarkOrange
    "#ffd700", // Gold
    "#8a2be2", // BlueViolet
    "#00ff7f", // SpringGreen
    "#ff0000", // Red
    "#00ffff", // Cyan
    "#0000ff", // Blue
    "#ff006e",
    "#8a2be2", // BlueViolet
    "#ff00ff", // Magenta
    "#ff1493", // DeepPink
    "#ff4500", // OrangeRed
    "#ff6347", // Tomato
    "#ffbe0b",
    "#ff8c00", // DarkOrange
    "#ff69b4", // HotPink
    "#ff1493", // DeepPink
    "#ff00ff", // Magenta
    "#00ffff", // Aqua
    "#32cd32", // LimeGreen
    "#ff6347", // Tomato
    "#dda0dd", // Plum
    "#1e90ff", // DodgerBlue
    "#da70d6", // Orchid
    "#7fff00", // Chartreuse
    "#ff69b4", // HotPink
    "#00ff00", // Lime
    "#32cd32", // LimeGreen
    "#1e90ff", // DodgerBlue
    "#da70d6", // Orchid
    "#ff69b4", // HotPink
    "#00ff00", // Lime
    "#32cd32", // LimeGreen
    "#1e90ff", // DodgerBlue
    "#da70d6", // Orchid
    "#ff69b4", // HotPink
    "#00ff00", // Lime
    "#32cd32", // LimeGreen
    "#1e90ff", // DodgerBlue
    "#da70d6", // Orchid
    "#ff69b4"  // HotPink
];