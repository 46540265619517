<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
	methods: {},
	props: ["chartData"],
	data: () => ({
		options: {
      legend: {
        position: 'bottom',
      },
			responsive: true,
			maintainAspectRatio: false,
		}
	}),
  watch: {
    deep: true,
    immediate: true,
    chartData() {
			this.renderChart(this.chartData, this.options);
    },
  },
	mounted() {
		this.renderChart(this.chartData, this.options);
	},
};
</script>

<style lang="scss"></style>
